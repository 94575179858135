import { Image } from '@graphcommerce/image'
import { StoreSwitcherButton } from '@graphcommerce/magento-store'
import { ContentLinks, Footer as FooterBase } from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import { Button, Divider, Grid, IconButton, Link } from '@mui/material'
import { Stack } from '@mui/system'
import PageLink from 'next/link'
import React from 'react'
import { FooterQueryFragment } from './FooterQueryFragment.gql'

export type FooterProps = FooterQueryFragment

export function Footer(props: FooterProps) {
  const { footer } = props

  console.log({ footer })
  return (
    <>
      <div
        style={{
          backgroundColor: '#662B58',
          color: 'white',
          paddingTop: '2rem',
          paddingBottom: '20px',
        }}
      >
        <ContentLinks
          title='Su Jode:'
          sx={{
            marginBottom: '21px',
            '& h2': {
              fontWeight: 'bold',
            },
          }}
        >
          <Stack
            direction='row'
            divider={<Divider orientation='vertical' flexItem color='white' />}
            spacing={2}
            sx={{ paddingLeft: '54px' }}
          >
            <Link href='/azienda' variant='body1' color='inherit'>
              Azienda
            </Link>

            <Link
              href='/privacy-policy'
              variant='body1'
              color='inherit'
              style={{ paddingRight: '24px' }}
            >
              Privacy
            </Link>

            <Link
              href='/contact/contact-us'
              variant='body1'
              color='inherit'
              style={{ paddingRight: '41px' }}
            >
              Contatti
            </Link>

            <Link href='/condizioni' variant='body1' color='inherit'>
              Condizioni di acquisto
            </Link>
          </Stack>
        </ContentLinks>
        <ContentLinks
          title='Servizio clienti:'
          sx={{
            marginBottom: '21px',
            '& h2': {
              fontWeight: 'bold',
            },
          }}
        >
          <Stack
            direction='row'
            divider={<Divider orientation='vertical' flexItem color='white' />}
            spacing={2}
          >
            <Link
              href='/service/come-ordinare'
              variant='body1'
              color='inherit'
              style={{ paddingRight: '16px' }}
            >
              Ordini
            </Link>

            <Link href='/service/payments' variant='body1' color='inherit'>
              Pagamenti
            </Link>

            <Link href='/service/scambi-resi' variant='body1' color='inherit'>
              Scambi e resi
            </Link>

            <Link href='/service/trasporto' variant='body1' color='inherit'>
              Opzioni di spedizione
            </Link>
          </Stack>
        </ContentLinks>

        <ContentLinks
          title='Il mio account:'
          sx={{
            marginBottom: '21px',
            '& h2': {
              fontWeight: 'bold',
            },
          }}
        >
          <Stack
            direction='row'
            divider={<Divider orientation='vertical' flexItem color='white' />}
            spacing={2}
          >
            <Link
              href='/account/signin'
              variant='body1'
              color='inherit'
              style={{
                paddingLeft: '3px',
              }}
            >
              Account
            </Link>

            <Link href='/wishlist' variant='body1' color='inherit'>
              Wishlist
            </Link>
          </Stack>
        </ContentLinks>
      </div>
      <FooterBase
        socialLinks={footer?.socialLinks?.map((link) => (
          <IconButton key={link.title} href={link.url} color='inherit' size='medium' edge='start'>
            {link.asset ? (
              <Image
                layout='fill'
                src={link.asset.url}
                width={24}
                height={24}
                unoptimized
                alt={link.title}
                sx={(theme) => ({
                  filter: theme.palette.mode === 'dark' ? 'invert(100%)' : 'invert(0%)',
                })}
              />
            ) : (
              link.title
            )}
          </IconButton>
        ))}
        storeSwitcher={<StoreSwitcherButton />}
        customerService={
          <Button href='/service' variant='pill'>
            <Trans id='Customer Service' />
          </Button>
        }
        copyright={
          <>
            <span>{footer?.copyright}</span>
            {footer?.legalLinks?.map((link) => (
              <Link key={link.title} href={link.url} color='textPrimary' underline='always'>
                {link.title}
              </Link>
            ))}
          </>
        }
      />
    </>
  )
}
