import { CartFab } from '@graphcommerce/magento-cart'
import {
  MagentoNavigationItemProps,
  magentoMenuToNavigation,
} from '@graphcommerce/magento-category'
import { CustomerFab, CustomerMenuFabItem } from '@graphcommerce/magento-customer'
import { SearchLink } from '@graphcommerce/magento-search'
import { WishlistFab, WishlistMenuFabItem } from '@graphcommerce/magento-wishlist'
import {
  DesktopNavActions,
  DesktopNavBar,
  LayoutDefault,
  LayoutDefaultProps,
  iconCustomerService,
  iconHeart,
  NavigationFab,
  MenuFabSecondaryItem,
  PlaceholderFab,
  IconSvg,
  DesktopNavItem,
  DarkLightModeMenuSecondaryItem,
  iconChevronDown,
  NavigationProvider,
  NavigationOverlay,
  useNavigationSelection,
  useMemoDeep,
  iconPhone,
} from '@graphcommerce/next-ui'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { Divider, Fab, Button, Box, Typography, Link, Menu, MenuItem } from '@mui/material'
import PageLink from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { CategoryPageDocument } from '../../graphql/CategoryPage.gql'
import { GetPageStaticProps } from '../../pages/search'
import { Footer } from './Footer'
import { LayoutQuery } from './Layout.gql'
import { Logo } from './Logo'
import { customMagentoMenuToNavigation } from './customMagentoMenuToNavigation'

export type LayoutNavigationProps = LayoutQuery &
  Omit<LayoutDefaultProps, 'footer' | 'header' | 'cartFab' | 'menuFab'>

const CustomButtonComponent = (props: MagentoNavigationItemProps) => {}

export function LayoutNavigation(props: LayoutNavigationProps) {
  const { footer, menu, children, ...uiProps } = props

  const selection = useNavigationSelection()
  const router = useRouter()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const siftMenu = (unsiftedMenu: LayoutQuery['menu']) => {
    if (!unsiftedMenu?.items || !unsiftedMenu?.items?.[0]?.children) return []
    // this function should filter out the menu items that are not needed
    // based on their "include_in_menu" property
    // and then return the menu items that are needed

    const filteredItems = unsiftedMenu?.items?.[0]?.children?.filter(
      (item) => item && item.include_in_menu === 1,
    )
    return filteredItems
  }

  const siftedMenu = siftMenu(menu)
  return (
    <>
      <NavigationProvider
        animationDuration={0}
        selection={selection}
        items={useMemoDeep(
          () => [
            <SearchLink
              href='/search'
              sx={(theme) => ({
                width: `calc(100% - ${theme.spacing(4)})`,
                m: 2,
                mb: theme.spacings.xs,
              })}
              aria-label={i18n._(/* i18n */ 'Search...')}
            >
              <Trans id='Search...' />
            </SearchLink>,
            ...magentoMenuToNavigation(menu, false),
            {
              id: 'camere-complete',
              href: '/camere-complete',
              name: 'Camere complete',
            },
            // {
            //   id: 'outlet',
            //   href: 'low-cost-outlet',
            //   name: 'Outlet',
            // },
            {
              id: 'contatti',
              href: '/contact/contact-us',
              name: 'Contatti',
            },
            { id: 'blog', name: 'Blog', href: 'https://jodenews.blogspot.com/' },
            <Divider sx={(theme) => ({ my: theme.spacings.xs })} />,
            <CustomerMenuFabItem key='account' guestHref='/account/signin' authHref='/account'>
              <Trans id='Account' />
            </CustomerMenuFabItem>,
            <MenuFabSecondaryItem
              key='service'
              icon={<IconSvg src={iconCustomerService} size='medium' />}
              href='/service'
            >
              <Trans id='Customer Service' />
            </MenuFabSecondaryItem>,
            <WishlistMenuFabItem key='wishlist' icon={<IconSvg src={iconHeart} size='medium' />}>
              <Trans id='Wishlist' />
            </WishlistMenuFabItem>,
          ],
          [menu],
        )}
      >
        <NavigationOverlay
          stretchColumns={false}
          variantSm='left'
          sizeSm='full'
          justifySm='start'
          itemWidthSm='340px'
          variantMd='left'
          sizeMd='full'
          justifyMd='start'
          itemWidthMd='340px'
          mouseEvent='hover'
          itemPadding='md'
        />
      </NavigationProvider>

      <Box>
        {/* <Box bgcolor='black' py='0.5rem' px='1rem'>
          <Typography variant='body1' align='center' color='white' fontWeight='bold'>
            Informiamo che i negozi di Firenze e Roma rimarrano chiusi per ferie dal 8 al 25 agosto
          </Typography>
        </Box> */}
        <div
          style={{
            backgroundColor: '#662b57',
            color: '#fff',
            fontSize: 11,
            textAlign: 'end',
            padding: '5px 40px 1px 1px',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <a
            style={{
              color: '#fff',
              textDecoration: 'none',
              fontWeight: 'bold',

              marginRight: 10,
              paddingRight: 10,
              borderRight: '1px solid #fff',
              height: 30,
            }}
            href='https://jodenews.blogspot.com/'
          >
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <Typography variant='body1'>Blog</Typography>
          </a>

          <a
            style={{
              color: '#fff',
              textDecoration: 'none',
              fontWeight: 'bold',
            }}
            href='tel:800603309'
          >
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img src='/Icons/numero_verde_bianco.svg' width='auto' height={40} />
          </a>
        </div>
      </Box>

      <LayoutDefault
        {...uiProps}
        noSticky={router.asPath.split('?')[0] === '/'}
        header={
          <>
            <Logo />
            <DesktopNavBar>
              {/* <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                {siftedMenu.items[0].children[0].children.map((item, index) => (
                  <MenuItem onClick={() => handleRedirect(item.url_path)}>{item.name}</MenuItem>
                ))}
              </Menu> */}
              <Button
                href='/camere-complete'
                variant='text'
                size='large'
                color='primary'
                sx={{ height: '45px', width: 'max-content' }}
                onClick={() => selection.set([siftedMenu[0]?.uid || ''])}
              >
                {siftedMenu[0]?.name}
                <IconSvg src={iconChevronDown} />
              </Button>

              <Button
                href='/camere-complete'
                variant='text'
                size='large'
                color='primary'
                sx={{ height: '45px', width: 'max-content' }}
              >
                Camere complete
              </Button>
              <Button
                href='/outlet-offerte-speciali'
                variant='text'
                size='large'
                color='primary'
                sx={{ height: '45px', width: 'max-content' }}
              >
                Outlet e Offerte
              </Button>
              <Button
                href='/contact/contact-us'
                variant='text'
                size='large'
                color='primary'
                sx={{ height: '45px', width: 'max-content' }}
              >
                Contatti
              </Button>
            </DesktopNavBar>
            <DesktopNavActions>
              {!router.pathname.startsWith('/search') && (
                <SearchLink href='/search' aria-label={i18n._(/* i18n */ 'Search...')} />
              )}
              <Link href='/service'>
                <Fab aria-label={i18n._(/* i18n */ 'Account')} size='large' color='inherit'>
                  <IconSvg src={iconCustomerService} size='large' />
                </Fab>
              </Link>
              <WishlistFab icon={<IconSvg src={iconHeart} size='large' />} />
              <CustomerFab guestHref='/account/signin' authHref='/account' />
              {/* The placeholder exists because the CartFab is sticky but we want to reserve the space for the <CartFab /> */}
              <PlaceholderFab />
            </DesktopNavActions>
          </>
        }
        footer={<Footer footer={footer} />}
        cartFab={<CartFab />}
        menuFab={<NavigationFab onClick={() => selection.set([])} />}
      >
        {children}
      </LayoutDefault>
    </>
  )
}
