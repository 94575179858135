import { Logo as LogoBase } from '@graphcommerce/next-ui'
import pngLogo from './JODesignLogo.png'

export function Logo() {
  return (
    <LogoBase
      sx={{
        '& .GcLogo-logo': {
          width: 'auto',
          height: { xs: '30px', md: '58px' },
          paddingLeft: { xs: '10px', md: 0 },
          marginTop: { xs: 0, md: '-5px' },
          filter: (theme) => (theme.palette.mode === 'dark' ? 'invert(100%)' : 'invert(0%)'),
        },
      }}
      image={{ alt: 'GraphCommerce Logo', src: pngLogo, unoptimized: true }}
    />
  )
}
